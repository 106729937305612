import React from 'react';
import { map, pathOr } from 'ramda';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import { InlineShareButtons, StickyShareButtons } from 'sharethis-reactjs';

import ArticleItem from '../components/ArticleItem';
import BlogCTA from '../components/BlogCTA';
import Layout from '../components/layout';
import SEO from '../components/seo';

export default function Template({ data }) {
    const { markdownRemark: post } = data;
    const { t } = useTranslation('article');
    if (!post) return null;

    const articles = pathOr([], ['allMarkdownRemark', 'edges'], data);
    const image = getImage(post.frontmatter.coverImage);

    return (
        <Layout>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.metaDescription}
                keywords={[
                    `Menus`,
                    `QR code`,
                    `Restaurant`,
                    `digital`,
                    `Commande en ligne`,
                    `carte de fidélité`
                ]}
            />
            <section className="bg-sapin-50 py-18 pb-24 flex flex-col justify-center items-center">
                <div className="w-full sm:max-w-6xl mt-20 sm:mt-28 sm:rounded-xl">
                    <GatsbyImage
                        image={image}
                        alt={post.frontmatter.title}
                        className="sm:rounded-xl w-full h-48 sm:h-96 object-cover"
                    />
                </div>
                <div className="mx-auto">
                    <div className="pb-4 sm:mt-8">
                        <span className="text-xs text-gray-800">
                            <Link
                                to="/blog"
                                className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                            >
                                Expertise & Conseils
                            </Link>
                            {post.frontmatter.mainCategory && (
                                <span>
                                    {' '}
                                    /{' '}
                                    <Link
                                        to={`/${post.frontmatter.mainCategory[1]}`}
                                        className="no-underline hover:underline text-gray-800 hover:text-orange-500"
                                    >
                                        {post.frontmatter.mainCategory[0]}
                                    </Link>{' '}
                                </span>
                            )}{' '}
                            / <span className="text-gray-600">{post.frontmatter.title}</span>
                        </span>
                    </div>
                    <div className="flex flex-col sm:flex-row mx-auto items-start justify-center">
                        <div className="container max-w-3xl sm:mt-0 sm:mb-8 sm:mr-8">
                            <div className="container max-w-3xl pb-6 rounded-xl bg-white p-4 sm:p-6 shadow-md">
                                <h1 className="w-full my-2 text-2xl sm:text-5xl font-bold leading-tight text-left text-black mb-8 bg-sapin-100 rounded-xl px-3">
                                    {post.frontmatter.title}
                                </h1>
                                <div className="my-2 text-md leading-tight text-left text-gray-500 mb-4">
                                    <Trans
                                        t={t}
                                        i18nKey="byAuthorAndDate"
                                        values={{
                                            author: post.frontmatter.author,
                                            date: moment(post.frontmatter.date).format('LL')
                                        }}
                                        components={{ b: <b /> }}
                                    />
                                </div>
                                <div className="w-10 my-2 text-md leading-tight text-left mb-8 border-b-4 border-sapin-400" />
                                <article
                                    className="prose prose-slate prose-img:rounded-xl prose-headings:underline prose-a:text-blue-600"
                                    dangerouslySetInnerHTML={{ __html: post.html }}
                                />
                                <div className="py-10">
                                    <InlineShareButtons
                                        config={{
                                            alignment: 'left',
                                            color: 'white',
                                            enabled: true,
                                            font_size: 16,
                                            labels: 'cta',
                                            language: 'fr',
                                            networks: [
                                                'messenger',
                                                'whatsapp',
                                                'facebook',
                                                'sharethis',
                                                'whatsapp'
                                            ],
                                            padding: 12,
                                            radius: 2,
                                            show_total: true,
                                            size: 40
                                        }}
                                    />
                                </div>
                            </div>
                            {articles.length > 0 && (
                                <h4 className="w-full my-2 text-3xl font-bold leading-tight text-left text-black mt-4 px-4 sm:px-0">
                                    {t('similarArticles')}
                                </h4>
                            )}
                            <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 mt-6 pb-6 px-4 sm:px-0">
                                {map(
                                    (article) => (
                                        <ArticleItem article={pathOr({}, ['node', 'frontmatter'], article)} />
                                    ),
                                    articles
                                )}
                            </div>
                        </div>
                        <BlogCTA />
                    </div>
                </div>
            </section>
            <StickyShareButtons
                config={{
                    alignment: 'left',
                    color: 'social',
                    enabled: true,
                    font_size: 16,
                    hide_desktop: false,
                    labels: 'counts',
                    language: 'fr',
                    min_count: 0,
                    networks: ['messenger', 'email', 'sharethis', 'facebook', 'whatsapp'],
                    padding: 12,
                    radius: 4,
                    show_total: true,
                    show_mobile: true,
                    show_toggle: true,
                    size: 48,
                    top: 160
                }}
            />
        </Layout>
    );
}

export const pageQuery = graphql`
    query BlogPostByPath($path: String!, $language: String!, $blogCategories: [String]) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                path
                title
                author
                mainCategory
                categories
                metaDescription
                coverImage {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
        }
        allMarkdownRemark(
            filter: {
                frontmatter: {
                    lang: { eq: $language }
                    path: { ne: $path }
                    categories: { in: $blogCategories }
                }
            }
            sort: { order: DESC, fields: [frontmatter___date] }
            limit: 4
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        author
                        mainCategory
                        categories
                        date
                        coverImage {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                        }
                    }
                }
            }
        }
    }
`;
